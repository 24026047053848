import * as url from "../constant/Endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { objectToQueryString } from "helper/urlquerystring";
import { dispatch } from "store/index";
import { AES, enc } from "crypto-js";
import {
  fetchAllVwwTrmsoitins,
  fetchUsersGetAll,
  ViewFetchBrnchs,
  ViewFetchLveTpye,
  ViewFetchDesignatns,
} from "store/action/index";

export default {
  fetchLoginUser: (payload, cb, timeout) => {
    if (payload.email === "" || payload.password === "") {
      payload?.setLoading(false);
      return false;
    }
    const data = {
      email: payload.email,
      password: payload.password,
    };
    PostData(url.base_url + url.logInApii, data)
      .then((res) => {
        const encryptedData = AES.encrypt(
          JSON.stringify(res.data.data.permissions),
          url.encryptionSecret
        ).toString();
        // const decryptedData = AES.decrypt(encryptedData, url.encryptionSecret).toString(enc.Utf8);
        // window.localStorage.setItem("NaveedKhan", JSON.stringify(decryptedData));

        console.log("ddqqqqqqqqqq", res.data);
        localStorage.setItem("newIdd", res.data.data.id);
        localStorage.setItem("logindata", encryptedData);
        localStorage.setItem("userData", JSON.stringify(res.data.data));
        localStorage.setItem(
          "userassigncompany",
          res.data.data.company.length == 0
        );
        localStorage.removeItem("CompanyId");

        localStorage.setItem("token", res.data.data.token);
        payload?.setLoading(false);
        toast.success("User Login Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        setTimeout(() => {
          window.location.reload();
          if (res.data.data.company.length == 0) {
            window.location.href = "/domain-parameter/create-company";
          }
        }, 500);

        cb(res.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },

  fetchStoreQuotation: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.storeQuatotion, payload.payload)
      .then((res) => {
        console.log("nkkhan", res.data);
        cb(res.data);
        // window.location = "/quotation/all-quotations";
        toast.success("Create Quotation Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload?.setLoading(false);

        // Download files
        // if (res.data && res.data.data.url) {
        //   res.data.data.url.forEach((v) => {
        //     console.log("ssasatheobject", v);
        //     window.open(v.url);
        //   });

        // }

        {
          res.data &&
            res.data.data.url.map((v, e) => {
              console.log("ssasathesssssosssbject", v);
              window.open(
                `${url.downlaodpdfurl}/download?url=${v.url}&filename=${v.filename}`
              );
              // window.location.href = `${url.base_url}/download?url=${v.url}&filename=${v.filename}`;
            });
        }

        // setTimeout(() => {
        //   window.location.href = "/quotation/all-quotations";
        // }, 1000);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },

  updateStoreQuotation: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.updateQuatotion, payload.payload)
      .then((res) => {
        cb(res.data);
        toast.success("Update Quotation Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload?.setLoading(false);
        // setTimeout(() => {
        //   window.location.href = "/quotation/all-quotations";
        // }, 1000);
        // window.location = "/quotation/all-quotations";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },

  fetchViewQuotation: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.viewQuatotion, payload.payload)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },

  ChangePassword: (payload, cb) => {
    const data = {
      current_password: payload.current_password,
      password: payload.password,
    };

    PostRegisterData(url.base_url + url.ChangePassword, data)
      .then((res) => {
        cb(res.data);
        {
          {
          }
        }
        payload.setLoading(false);
        toast.success("Password Change Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload.setModalOpen(false);

        window.location.href = "/dashboard";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          payload.setLoading(false);
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        payload.setLoading(false);
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchInvoiceRegisterr: (payload, cb, timeout) => {
    const data = payload.objj;

    NewPostData(url.base_url + url.invoiceSend, data)
      .then((res) => {
        cb(res.data);

        if (res?.data && res?.data?.data) {
          payload?.setLoading(false);
          window.open(`${res.data.data.url}`);
        }
        // res.data.message
        toast.success("Create Invoice Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.open(`${res.data.data.url}`);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchMainInvoices: (payload, cb, timeout) => {
    const data = payload.payload;
    NewPostData(url.base_url + url.mainInvoice, data)
      .then((res) => {
        console.log("minhaj", url.base_url + url.mainInvoice, data);
        cb(res.data);
        console.log("", res.data);
        console.log("nkkhan", [res.data.data]);
        toast.success("Create Invoice Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);

        // {
        //   res.data && [res.data.data].map((v, e) => {
        //     console.log("ssasathesssobject", v)
        //     window.open(`${url.downlaodpdfurl}/download?url=${v.url}&filename=${v.filename}`)
        //     window.location.href = `${url.base_url}/download?url=${v.url}&filename=${v.filename}`;

        //   })
        // }
        // setTimeout(() => {
        //   window.location.href = "/invoice/all-invoices";
        // }, 3000);

        {
          res.data &&
            res.data.data.url.map((v, e) => {
              console.log("ssasathesssssosssbject", v);
              window.open(
                `${url.downlaodpdfurl}/download?url=${v.url}&filename=${v.filename}`
              );
              // window.location.href = `${url.base_url}/download?url=${v.url}&filename=${v.filename}`;
            });
        }

        // setTimeout(() => {
        //   window.location.href = "/invoice/all-invoices";
        // }, 1500);
      })

      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },
  updateMainInvoices: (payload, cb, timeout) => {
    const data = payload.payload;
    NewPostData(url.base_url + url.invoicesupdatee, data)
      .then((res) => {
        cb(res.data);
        toast.success("Update Invoice Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);

        // window.location.href = "/invoice/all-invoices";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },

  fetchChallan: (payload, cb, timeout) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.challann, data)
      .then((res) => {
        cb(res.data);
        console.log("nkkhan", res.data);
        toast.success("Create Delivery Challan Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        console.log("nenenessswwheheh", res.data.data.url);
        {
          res.data &&
            res.data.data.url.map((v, e) => {
              console.log("ssasathesssssosssbject", v);
              window.open(
                `${url.downlaodpdfurl}/download?url=${v.url}&filename=${v.filename}`
              );
              // window.location.href = `${url.base_url}/download?url=${v.url}&filename=${v.filename}`;
            });
        }
        // setTimeout(() => {
        //   window.location.href = "/delivery-note/all-challan";
        // }, 1200);
      })
      .catch((err) => {
        console.warn(err);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload?.setLoading(false);
      });
  },

  fetchUpdateCompany: (payload, cb) => {
    const data = new FormData();
    payload?.setLoading(true);

    data.append("id", payload.id);
    data.append("company_name", payload.company_name);
    data.append("company_email", payload.company_email);
    data.append("inv_prefix", payload.invoice_prefix);
    data.append("quo_prefix", payload.qoutation_prefix);
    data.append("del_prefix", payload.dc_prefix);
    data.append("account_number", payload.account_number);
    data.append("company_address", payload.company_address);
    data.append("phone_number", payload.company_phone);
    data.append("account_iban", payload.account_iban);
    data.append("ntn_no", payload.ntn_no);
    data.append("account_name", payload.account_name);
    data.append("branch_code", payload.branch_code);
    data.append("bank_name", payload.bank_name);
    data.append("quotation_term", payload.quotation_term);
    data.append("invoice_term", payload.invoice_term);
    data.append("company_type", payload.company_type);
    data.append("signature", payload.signature);

    if (
      payload.company_address1 &&
      payload.company_address1 !== null &&
      payload.company_address1 !== undefined &&
      payload.company_address1 !== ""
    ) {
      data.append("company_address1", payload.company_address1);
    }

    if (
      payload.company_phone1 &&
      payload.company_phone1 !== null &&
      payload.company_phone1 !== undefined &&
      payload.company_phone1 !== ""
    ) {
      data.append("phone_number2", payload.company_phone1);
    }
    if (
      payload.company_email1 &&
      payload.company_email1 !== null &&
      payload.company_email1 !== undefined &&
      payload.company_email1 !== ""
    ) {
      data.append("company_email1", payload.company_email1);
    }
    if (
      payload.company_logo &&
      payload.company_logo !== null &&
      payload.company_logo !== undefined &&
      payload.company_logo !== ""
    ) {
      data.append("company_logo", payload.company_logo);
    }

    if (
      payload.company_watermark &&
      payload.company_watermark !== null &&
      payload.company_watermark !== undefined &&
      payload.company_watermark !== ""
    ) {
      data.append("watermark", payload.company_watermark);
    }

    PostRegisterData(url.base_url + url.updateCompany, data)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
        toast.success("Update Company Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        setTimeout(() => {
          window.location.href = "/domain-parameter/all-company";
        }, 1500);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchEditCompany: (payload, cb, timeout) => {
    const dasta = payload;

    NewPostData(url.base_url + url.customerEdit, dasta)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EduitChallabn: (payload, cb, timeout) => {
    const dasta = payload;
    NewPostData(url.base_url + url.challanupdatess, dasta)
      .then((res) => {
        window.challanUpdateTable();
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
      });
  },

  fetchUpdateTaxes: (payload, cb, timeout) => {
    payload?.setLoading(true);
    const data = new FormData();
    data.append("id", payload.id);
    data.append("tax_name", payload.tax_name);
    data.append("tax_value", payload.tax_value);
    data.append("tax_type", payload.tax_type);

    NewPostData(url.base_url + url.updateTaxes, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);

        toast.success("Updated Tax Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        setTimeout(() => {
          window.location.href = "/domain-parameter/all-taxes";
        }, 1500);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchEditAccounts: (payload, cb, timeout) => {
    const dasta = payload;
    payload?.setLoading(true);
    NewPostData(url.base_url + url.editChartaccountt, dasta)
      .then((res) => {
        cb(res.data);
        toast.success("Updated Accounts Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);

        // setTimeout(() => {
        //   window.location.href = "/domain-parameter/chart-of-account";
        // }, 1500);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },

  fetchDeleteCompany: (payload, cb, timeout) => {
    const dasta = payload.objectt;

    NewPostData(url.base_url + url.deleteCompany, dasta)
      .then((res) => {
        payload?.setLoading(false);
        window.UpdateTable();
        cb(res.data);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
      });
  },

  DeleteChartAccount: (payload, cb) => {
    payload?.setLoading(true);

    NewPostData(url.base_url + url.deleteAccountOFchart, { id: payload.id })
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);

        toast.success("Delete Account Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchDeleteCustomer: (payload, cb, timeout) => {
    const dasta = payload;

    NewPostData(url.base_url + url.customerDelete, dasta)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },
  HandleFetchStatusLeaveType: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.statusleavtypeapi, payload?.pagespayload)
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchLveTpye());
        toast.success("Update Status Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchActiveDeactiveDSNTSStore: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.apistatusdesgn, payload?.pagespayload)
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchDesignatns());
        toast.success("Update Status Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchActiveDeactiveBrnStore: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.apistatusbrand, payload?.pagespayload)
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchBrnchs());
        toast.success("Update Status Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchTrmsCotntionDeletee: (payload, cb, timeout) => {
    payload?.setLoading(true);
    NewPostData(url.base_url + url.termDelete, { id: payload?.id })
      .then((res) => {
        toast.success("Delete Terms and Conditions Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(fetchAllVwwTrmsoitins());
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      });
  },

  fetchDeleteTax: (payload, cb) => {
    const dasta = payload.userObject;
    payload?.setLoading(true);
    NewPostData(url.base_url + url.deleteTaxes, dasta)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        window.taxRerenderTable();
      })
      .catch((err) => {
        payload?.setLoading(false);
      });
  },

  fetchRegisterCompany: (payload, cb, timeout) => {
    payload?.setLoading(true);
    console.log(payload.company_logo);
    const data = new FormData();

    data.append(
      "company_name",
      payload.company_name !== undefined ? payload.company_name : ""
    );
    data.append(
      "inv_prefix",
      payload.invoice_prefix !== undefined ? payload.invoice_prefix : ""
    );
    data.append(
      "quo_prefix",
      payload.qoutation_prefix !== undefined ? payload.qoutation_prefix : ""
    );
    data.append(
      "del_prefix",
      payload.dc_prefix !== undefined ? payload.dc_prefix : ""
    );
    data.append(
      "company_email",
      payload.company_email !== undefined ? payload.company_email : ""
    );
    data.append(
      "account_number",
      payload.account_number !== undefined ? payload.account_number : ""
    );
    data.append(
      "company_address",
      payload.company_address !== undefined ? payload.company_address : ""
    );
    data.append(
      "phone_number",
      payload.phone_number !== undefined ? payload.phone_number : ""
    );
    data.append(
      "phone_number",
      payload.company_phone !== undefined ? payload.company_phone : ""
    );

    data.append(
      "account_iban",
      payload.account_iban !== undefined ? payload.account_iban : ""
    );
    data.append(
      "account_name",
      payload.account_name !== undefined ? payload.account_name : ""
    );
    data.append(
      "company_logo",
      payload.company_logo !== undefined ? payload.company_logo : ""
    );
    data.append(
      "company_watermark",
      payload.company_watermark !== undefined ? payload.company_watermark : ""
    );

    data.append(
      "branch_code",
      payload.branch_code !== undefined ? payload.branch_code : ""
    );
    data.append(
      "bank_name",
      payload.bank_name !== undefined ? payload.bank_name : ""
    );
    data.append(
      "quotation_term",
      payload.quotation_term !== undefined ? payload.quotation_term : ""
    );
    data.append(
      "invoice_term",
      payload.invoice_term !== undefined ? payload.invoice_term : ""
    );
    data.append(
      "company_type",
      payload.company_type !== undefined ? payload.company_type : ""
    );
    data.append(
      "company_website",
      payload.company_website !== undefined ? payload.company_website : ""
    );
    data.append(
      "invoice_template",
      payload.invoice_template !== undefined ? payload.invoice_template : ""
    );
    data.append(
      "delivery_template",
      payload.delivery_template !== undefined ? payload.delivery_template : ""
    );
    data.append(
      "quotation_template",
      payload.quotation_template !== undefined ? payload.quotation_template : ""
    );
    data.append("ntn_no", payload.ntn_no !== undefined ? payload.ntn_no : "");
    data.append(
      "signature",
      payload.signature !== undefined ? payload.signature : ""
    );

    PostRegisterData(url.base_url + url.registerCompany, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Create Company Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        let assignUserChecking = localStorage.getItem("userassigncompany");
        if (assignUserChecking == "false") {
          setTimeout(() => {
            window.location.href = "/domain-parameter/all-company";
          }, 1500);
        } else {
          localStorage.clear();
          window.location.reload();
          window.location.href = "/";
        }
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchRegisterTaxxx: (payload, cb, timeout) => {
    payload?.setLoading(true);
    const data = new FormData();
    data.append("tax_name", payload.tax_name);
    data.append("tax_value", payload.tax_value);
    data.append("tax_type", payload.tax_type);

    PostRegisterData(url.base_url + url.taxRegister, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);

        toast.success("Created Tax Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        setTimeout(() => {
          window.location.href = "/domain-parameter/all-taxes";
        }, 1500);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchAllRoleView: (payload, cb) => {
    getData(
      url.base_url +
        url.allRoleApi +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
      });
  },

  CreateFetchRolee: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.registerRollsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  EditRole: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.editRoleAPI, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  AssignPermissionsShop: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.assignpermissionsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Permissions Assigned Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchTermsCondtiosnsn: (payload, cb, timeout) => {
    const data = payload.userObj;

    PostRegisterData(url.base_url + url.fetchsendterm, data)
      .then((res) => {
        cb(res.data);
        console.log("nkkhan", res.data);
        payload?.setLoading(false);
        toast.success("Create Terms Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        setTimeout(() => {
          window.location.href = "/domain-parameter/terms-and-conditions";
        }, 1500);
      })
      .catch((err) => {
        console.warn(err);
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        setTimeout(() => {
          window.location.href = "/domain-parameter/terms-and-conditions";
        }, 1500);
      });
  },

  fetchRegistereacccount: (payload, cb, timeout) => {
    const data = payload;

    PostRegisterData(url.base_url + url.registerAccountDataae, data)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
        toast.success("Create Account Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        setTimeout(() => {
          window.location.href = "/domain-parameter/chart-of-account";
        }, 1500);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchRegisterCustomerrr: (payload, cb, timeout) => {
    const data = payload;
    PostRegisterData(url.base_url + url.customerRegister, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Customer Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        setTimeout(() => {
          window.location.href = "/domain-parameter/all-customer";
        }, 1500);
      })
      .catch((err) => {
        payload?.setLoading(false);
        console.warn(err);
        toast.error(
          err?.response?.data?.message ||
            "An error occurred. Please try again later.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          }
        );
      });
  },

  fetchtemrsCotitonsEdit: (payload, cb, timeout) => {
    const data = payload?.userObj;
    PostRegisterData(url.base_url + url.termUpdatee, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Edit Terms & Condition Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        payload?.setLoading(false);
      });
  },

  fetchTermcoftioDetailsseses: (payload, cb, timeout) => {
    const data = payload.objj;

    PostRegisterData(url.base_url + url.detailsTermms, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  detchViewChallanDetails: (payload, cb, timeout) => {
    const data = payload;
    console.log(data, "Payload");
    NewPostData(url.base_url + url.challandetails, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  fetchNEwDEtailsQuoata: (payload, cb, timeout) => {
    const data = payload;

    PostRegisterData(url.base_url + url.quotationdetails, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  fetchHandleTemplateDetails: (payload, cb, timeout) => {
    const data = payload;

    PostRegisterData(url.base_url + url.templatedertilss, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  fetchInvoiceDetails: (payload, cb, timeout) => {
    const data = payload?.newObjj;
    console.log("tetetette", data);
    PostRegisterData(url.base_url + url.invoicedetails, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  fetchPaymentVocuherr: (payload, cb, timeout) => {
    const data = payload.userObj;

    PostRegisterData(url.base_url + url.paymentapifetches, data)
      .then((res) => {
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/domain-parameter/payment-voucher";
        }, 1000);
        payload?.setLoading(false);
        toast.success("Create Payment Voucher Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        console.warn(err);
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  fetchExpenseVocuherr: (payload, cb, timeout) => {
    const data = payload.userObj;

    PostRegisterData(url.base_url + url.expenseapifetches, data)
      .then((res) => {
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/domain-parameter/payment-voucher";
        }, 1000);
        payload?.setLoading(false);
        toast.success("Create Expense Voucher Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        console.warn(err);
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  updatePaymentVocuherr: (payload, cb, timeout) => {
    const data = payload.userObj;

    PostRegisterData(url.base_url + url.paymentapiupdate, data)
      .then((res) => {
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/domain-parameter/payment-voucher";
        }, 500);
        payload?.setLoading(false);
        toast.success("Update Payment Voucher Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        console.warn(err);
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchTemplateSend: (payload, cb, timeout) => {
    const NewData = new FormData();

    NewData.append("template_name", payload.template_name);
    NewData.append("template_code", payload.template_code);
    NewData.append("template_type", payload.template_type);

    console.log(NewData);
    PostRegisterData(url.base_url + url.templateregisteeerr, payload)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Create Template Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        setTimeout(() => {
          window.location.href = "/domain-parameter/all-templates";
        }, 1500);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  DesinationsFetchCreatee: (payload, cb, timeout) => {
    NewPostData(
      url.base_url + url.createapiroutedesignation,
      payload?.pagepayload
    )
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchDesignatns());
        toast.success("Add Designation Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EditHndleStoreDisnts: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.designationedit, payload?.pagepayloads)
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchDesignatns());
        toast.success("Edit Designation Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EditLeaveTYPEStore: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.leavtpeapiedit, payload?.pagepayloads)
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchLveTpye());
        toast.success("Edit Leave Type Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CreateDeductionSlry: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.crcdeduction, data)
      .then((res) => {
        cb(res.data);
        toast.success("Created Deduction Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  DeleteDeductionSlry: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.deletededuction, data)
      .then((res) => {
        cb(res.data);
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EditDeductionSlry: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.editdeduction, data)
      .then((res) => {
        cb(res.data);
        toast.success(" Deduction Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CreateFetchSalary: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.createsalaryapi, payload?.payloades)
      .then((res) => {
        cb(res.data);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.data?.emp_id?.length !== 0
        //     ? err?.response?.data?.data?.emp_id[0]
        //     : "",
        //   {
        //     position: "top-right",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //     bodyClassName: "toastStyle",
        //   }
        // );
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  HanldeFetchAllEmp: (payload, cb, timeout) => {
    NewPostData(
      url.base_url + url.deletepayrollemloyeeexpense,
      payload?.payloadses
    )
      .then((res) => {
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {});
  },
  FetchEditBranchesHnde: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.brancheditapi, payload?.pagepayloads)
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchBrnchs());
        toast.success("Edit Branch Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CreateFetchBranch: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.createapiroutebranch, payload?.pagepayload)
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchBrnchs());
        toast.success("Add Branch Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CreateLeaveTypeeFetch: (payload, cb, timeout) => {
    NewPostData(
      url.base_url + url.createapirouteleavetype,
      payload?.pagepayload
    )
      .then((res) => {
        cb(res.data);
        dispatch(ViewFetchLveTpye());
        toast.success("Create Leave Type Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CreateRecuringFetch: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.fetchrecuringemail, payload)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Create Recuring Email Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        payload?.setLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  detailsUsersDeleteAll: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.fetchrstatus, payload)
      .then((res) => {
        dispatch(fetchUsersGetAll());
        cb(res.data);
        payload?.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        payload?.setLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  tchpaymentVoucherVieww: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";
    getData(
      `${url.base_url}${url.paymentvoucherallgetet}${
        "?company_id=" + localStorage.getItem("companyName")
      }${searchQuery}${
        payload?.pagination ? "&page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
      });
  },
  // escapeHtmlEntities: (html) => {
  //   return html.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  // },
  fetchAllVwwTrmsoitins: (payload, cb) => {
    payload?.setLoading(true);
    const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.termallGt +
        "?company_id=" +
        localStorage.getItem("companyName") +
        searchQuery
    )
      .then((res) => {
        console.log("nkkhan", res.data.data);
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchAllTrmActive: (payload, cb) => {
    payload?.setLoading(true);
    const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.termallAct +
        "?company_id=" +
        localStorage.getItem("companyName") +
        searchQuery
    )
      .then((res) => {
        console.log("nkkhan", res.data.data);
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  getAllPermissions: (payload, cb) => {
    getData(
      url.base_url +
        url.allpermissionsApi +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  GetRolePermissions: (payload, cb) => {
    getData(
      url.base_url +
        url.getRolePermissionsApi +
        `${payload?.roleId}${
          "&company_id=" + localStorage.getItem("companyName")
        }`
    )
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  // __________________________________DAHSBOARD_________________________________________________

  GetAllinvoiceReports: (payload, cb) => {
    getData(url.base_url + url.getAllinvoiceReportsApi)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  GetAllquotationsReports: (payload, cb) => {
    getData(url.base_url + url.getAllquotationsReportsApi)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  GetAllchallansReports: (payload, cb) => {
    getData(url.base_url + url.getAllchallansReportsApi)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },
  GetTopRatedCustomers: (payload, cb) => {
    getData(url.base_url + url.getTopRatedCustomers)
      .then((res) => {
        // console.log("nkkhan",res);
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },
  // __________________________________DAHSBOARD_________________________________________________

  GetAllledgerReports: (payload, cb) => {
    getData(
      url.base_url +
        url.getAllledgerReportsApi +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  AllInvoiceExp: (payload, cb) => {
    const searchQuery = payload?.params ?? "";

    getData(url.base_url + url.getinvoiceexportsss + searchQuery)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  AllVoucherExp: (payload, cb) => {
    getData(url.base_url + url.getvoucherexportsss)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  AllQuotationsExp: (payload, cb) => {
    const searchQuery = payload?.params ?? "";

    getData(url.base_url + url.getquotationsexportsss + searchQuery)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  AllChallanExp: (payload, cb) => {
    const searchQuery = payload?.params ?? "";

    getData(url.base_url + url.getchallansexportsss + searchQuery)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  // __________________________________REPORTS______________________________________________

  // _________________________________REPORTS____________________________________________________

  AssignPermissionsShop: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.assignpermissionsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Permissions Assigned Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchCompanyDataa: (payload, cb, timeout) => {
    getData(
      url.base_url + url.companyGetApi + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  AllQuotationDel: (payload, cb) => {
    const dataa = payload.objecttt;
    PostRegisterData(url.base_url + url.quotationDelete, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Quotation Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  AllInvoiceDel: (payload, cb) => {
    const dataa = payload.objjecttt;
    PostRegisterData(url.base_url + url.invoiceDelete, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Invoice Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  AllChallanDel: (payload, cb) => {
    const dataa = payload.objjecttt;
    PostRegisterData(url.base_url + url.challanDelete, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Challan Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  AllVoucherDel: (payload, cb) => {
    const dataa = payload.objjecttt;
    PostRegisterData(url.base_url + url.voucherDeelete, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Voucher Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CrcAllLoans: (payload, cb) => {
    const dataa = payload?.payload;
    PostRegisterData(url.base_url + url.loanscrc, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Loan Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        setTimeout(() => {
          window.location.href = "/Loan-management/all-loans";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CrcReturnLoans: (payload, cb) => {
    const dataa = payload?.payload;
    PostRegisterData(url.base_url + url.loanscrcreturn, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Loan Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        setTimeout(() => {
          window.location.href = "/Loan-management/all-loans";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  EditAllLoans: (payload, cb) => {
    const dataa = payload?.payload;
    PostRegisterData(url.base_url + url.loansedit, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Loan Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        setTimeout(() => {
          window.location.href = "/Loan-management/all-loans";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  CustomerrrDataa: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.customerGetApii +
        "?company_id=" +
        localStorage.getItem("companyName") +
        searchQuery
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetCompanypolicy: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.comppolicy +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetAllLoans: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.getloans)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetAllPayslip: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.getpayslip +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetAllPayslry: (payload, cb) => {
    const dataa = payload?.payload;
    PostRegisterData(url.base_url + url.getpaysalary, dataa)
      .then((res) => {
        cb(res.data);
        toast.success("Salary Pay Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        setTimeout(() => {
          window.location.href = "/hrm-modules/payroll-payslip";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  GetAllPayroll: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.getpayroll)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetAmountDeduction: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.getdeductions)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetAllowanceAllPayroll: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.getttallowance)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetCurrencyAll: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.getcurrency)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetAllEmpAllowance: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.employeeallowance)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetEmpslry: (payload, cb, timeout) => {
    const searchQuery = payload?.payloadss ?? "";
    NewPostData(url.base_url + url.employeegetsalryyy, searchQuery)
      .then((res) => {
        cb(res.data);
        payload?.setLoadingES(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoadingES(false);
      });
  },

  GetPayrollAllowance: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.payrollAllowance)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  CrcCurrencyAll: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.currencycreate, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  EditCurrencyAll: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.currencyupdate, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  DeleteCurrencyAll: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.currencydelete, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  EditAllowanceAllPayroll: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.allowupdate, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  AllowanceDelete: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.allowdelete, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CrcAllowanceAllPayroll: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.allowcreate, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  UpdateSaturationDeduct: (payload, cb, timeout) => {
    const data = payload?.payloadssw;
    NewPostData(url.base_url + url.satdeductupdate, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  UpdtaeAllEmpAllowance: (payload, cb, timeout) => {
    const data = payload?.payloadssw;
    NewPostData(url.base_url + url.empsalaryupdate, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  GetAllAttendance: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.getattendance +
        "?company_id=" +
        localStorage.getItem("companyName") +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  AllLeaveMang: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.leaveall)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  AllEmployees: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";
    getData(
      url.base_url + url.employeeall + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  GetLoanTypes: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(url.base_url + url.loantypeall)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  CreateEmpSalary: (payload, cb, timeout) => {
    const data = payload?.payloadss;
    NewPostData(url.base_url + url.empsalary, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.reload();
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  UpdateAllOtherPayments: (payload, cb, timeout) => {
    const data = payload?.payloadssw;
    NewPostData(url.base_url + url.otherpayments, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  ExtractAllAttendance: (payload, cb, timeout) => {
    const data = payload?.paylods;
    NewPostData(url.base_url + url.attendanceextract, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();

          window.location = "/";
          window.location.reload();
          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CreateAllAttendance: (payload, cb, timeout) => {
    const data = new FormData();

    data.append(
      "csv_file",
      payload.csv_file !== undefined ? payload.csv_file : ""
    );

    data.append(
      "for_month",
      payload.for_month !== undefined ? payload.for_month : ""
    );
    data.append(
      "company_id",
      payload.company_id !== undefined ? payload.company_id : ""
    );
    PostRegisterData(url.base_url + url.attendancecreate, data)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  EmployeesCr: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.employeecreate, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/hrm-modules/employee-info";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  EmployeesUpdate: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.employeeupdate, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/hrm-modules/employee-info";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CrcPayroll: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.payrollcreate, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/hrm-modules/employee-info";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CrcRoster: (payload, cb, timeout) => {
    const data = payload?.paylosad;
    NewPostData(url.base_url + url.rosterrcrc, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        // setTimeout(() => {
        //   window.location.href = "/hrm-modules/employee-info";
        // }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  UpdateLeaveMang: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.leavesedit, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/leave-management/all-leaves";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  DeleteLeaveMang: (payload, cb, timeout) => {
    const datasss = payload?.payload;
    NewPostData(url.base_url + url.delleaves, datasss)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        // setTimeout(() => {
        //   window.location.href = "/hrm-modules/employee-info";
        // }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CrcCompanypolicy: (payload, cb, timeout) => {
    const datasss = payload?.jsonPayload;
    NewPostData(url.base_url + url.policyCrc, datasss)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CreateLeaveMang: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.manageleaveecreate, data)
      .then((res) => {
        // payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        // setTimeout(() => {
        //   window.location.href = "/domain-parameter/all-customer";
        // }, 1500);
      })
      .catch((err) => {
        // payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CreateLeaveMang: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.manageleaveecreate, data)
      .then((res) => {
        // payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/leave-management/all-leaves";
        }, 1500);
      })
      .catch((err) => {
        // payload.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CrLeaveMang: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.leavemancreate, data)
      .then((res) => {
        // payload.setLoading(false);
        // toast.success(res.data.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: "toastStyle",
        // });
        cb(res.data);
        // setTimeout(() => {
        //   window.location.href = "/domain-parameter/all-customer";
        // }, 1500);
      })
      .catch((err) => {
        // payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  AllLeavesMangGet: (payload, cb, timeout) => {
    // const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.managleaveget +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          // window.location.reload();
        }

        payload?.setLoading(false);
      });
  },
  EditGenerateSlry: (payload, cb, timeout) => {
    const data = payload;
    NewPostData(url.base_url + url.generateslryyyedit, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/domain-parameter/all-customer";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CrGenerateSlry: (payload, cb, timeout) => {
    const data = payload?.payload;
    NewPostData(url.base_url + url.generateslryyy, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);

        {
          res.data &&
            res.data.data.url.map((v, e) => {
              console.log("ssasathesssssosssbject", v);
              window.open(
                `${url.downlaodpdfurl}/download?url=${v.url}&filename=${v.filename}`
              );
              // window.location.href = `${url.base_url}/download?url=${v.url}&filename=${v.filename}`;
            });
        }
        setTimeout(() => {
          window.location.href = "/hrm-modules/payroll-management";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CustomerrrEditDataa: (payload, cb, timeout) => {
    const data = payload;
    NewPostData(url.base_url + url.customerUpdateApii, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/domain-parameter/all-customer";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  CustomerrrDeleteDataa: (payload, cb, timeout) => {
    const data = payload;
    NewPostData(url.base_url + url.customerDeleteApii, data)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/domain-parameter/all-customer";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  Unlockpdff: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.pdffunlcok, payload?.payload)
      .then((res) => {
        payload.setLocksGets("2");
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        console.log("erororroor", err);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  ReqLeaveapprove: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.leavesreq, payload?.payloads)
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        console.log("erororroor", err);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  ReqLeavecancel: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.leavesreqcancel, payload?.payloadddd)
      .then((res) => {
        toast.success("Cancel Leave Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        console.log("erororroor", err);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  ReqLeavereject: (payload, cb, timeout) => {
    NewPostData(url.base_url + url.leavesreqreject, payload?.payloadddds)
      .then((res) => {
        toast.success("Reject Leave Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        console.log("erororroor", err);
        toast.error(err?.response?.data?.data, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchGetAllReports: (payload, cb, timeout) => {
    getData(
      url.base_url + url.allreportApi + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  increptionDataStore: (payload, cb, timeout) => {
    getData(
      url.base_url + url.increptionpass + "?" + "tx=" + payload?.payload?.tx
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }
      });
  },

  ViewFetchLveTpye: (payload, cb, timeout) => {
    getData(
      url.base_url +
        url.createapigteerouteleavetype +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  ViewFetchBrnchs: (payload, cb, timeout) => {
    getData(
      url.base_url +
        url.creategeteapiroutebranch +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  ViewFetchDesignatns: (payload, cb, timeout) => {
    getData(
      url.base_url +
        url.createapigeetroutedesignation +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  // LeaveMangGet: (payload, cb, timeout) => {
  //   getData(
  //     url.base_url + url.getleavemangapi)
  //     .then((res) => {
  //       cb(res.data);
  //       payload?.setLoading(false);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         // Redirect to the login page
  //         localStorage.clear();
  //         window.location.reload();
  //       }

  //       payload?.setLoading(false);
  //     });
  // },

  FetchRecuringData: (payload, cb, timeout) => {
    getData(
      url.base_url +
        url.recuringallget +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        payload?.setLoading(false);
      });
  },

  fetchCurrency: (payload, cb, timeout) => {
    getData(url.base_url + url.getCurrency)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        payload?.setLoading(false);
      });
  },
  fetchDashboard: (payload, cb, timeout) => {
    getData(`${url.base_url}${url.getDashboard}`)
      .then((res) => {
        cb(res.data);
        payload?.setLOading(true);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        payload?.setLOading(true);
      });
  },
  PaymentVoucherDetail: (payload, cb, timeout) => {
    getData(
      url.base_url +
        url.getPaymentVoucherDetail +
        payload?.newObjj?.id +
        "&company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }
      });
  },

  fetchTaxesComp: (payload, cb) => {
    const searchQuery = payload?.params ?? "";
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.taxesCompany +
        "?company_id=" +
        localStorage.getItem("companyName") +
        searchQuery
    )
      .then((res) => {
        cb(res.data);

        payload?.setLoading(false);
      })
      .catch((err) => {
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload?.setLoading(false);
      });
  },

  fetchNumberField: (payload, cb, timeout) => {
    const data = payload;
    NewPostData(url.base_url + url.numberField, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  fetchCustomer: (payload, cb, timeout) => {
    getData(
      url.base_url +
        url.cutomerrsGetApi +
        "?company_id=" +
        localStorage.getItem("companyName")
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  fetchQuoatationGetAll: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";
    getData(
      `${url.base_url}${url.AllViewQuatationss}?company_id=${parseInt(
        localStorage.getItem("companyName")
      )}${payload?.pagination ? "&page=" + payload?.pagination : ""}${
        payload?.paramsObjj?.company_name !== undefined
          ? "?company_id=" + payload?.paramsObjj?.company_name
          : ""
      }${
        payload?.paramsObjj?.customer_name !== undefined
          ? "&customer_id=" + payload?.paramsObjj?.customer_name
          : ""
      }${
        payload?.paramsObjj?.from_date !== undefined
          ? "&from_date=" + payload?.paramsObjj?.from_date
          : ""
      }${
        payload?.paramsObjj?.to_date !== undefined
          ? "&to_date=" + payload?.paramsObjj?.to_date
          : ""
      }${searchQuery}`
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        payload?.setLoading(false);
      });
  },

  fetchUsersGetAll: (payload, cb, timeout) => {
    console.log(payload);
    getData(
      `${url.base_url}${url.AllUsers}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }    `
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        payload?.setLoading(false);
      });
  },

  detailsUsersGetAll: (payload, cb, timeout) => {
    getData(`${url.base_url}${url.AllUsersDetails}${payload?.iddd}`)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        payload?.setLoading(false);
      });
  },

  CreateAllUsers: (payload, cb, timeout) => {
    payload.setLoading(false);
    var usr_url =
      payload.id !== undefined
        ? url.base_url + url.UpdateUsers
        : url.base_url + url.CreateUsers;
    console.log("ghghghgh123", usr_url);
    NewPostData(usr_url, payload)
      .then((res) => {
        payload.setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        setTimeout(() => {
          window.location.href = "/userManagment/all-users";
        }, 500);
      })
      .catch((err) => {
        const errorMessages = err?.response?.data?.data;

        if (errorMessages) {
          Object.keys(errorMessages).forEach((key) => {
            const messages = errorMessages[key];
            messages.forEach((message) => {
              toast.error(`${key}: ${message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                bodyClassName: "toastStyle",
              });
            });
          });
        }
      });
  },

  fetchAllInvoice: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";
    getData(
      `${url.base_url}${url.invoicegetdata}${
        "?company_id=" + localStorage.getItem("companyName")
      }${
        payload?.pagination ? "&page=" + payload?.pagination : ""
      }${searchQuery}${
        payload?.selectedCompany !== undefined
          ? "&customer_id=" + payload?.selectedCompany + "&paginate=all"
          : ""
      }${
        payload?.customer_id !== undefined
          ? "?customer_id=" + payload?.customer_id + "&paginate=all"
          : ""
      }
      ${
        payload?.from_date !== undefined
          ? "&from_date=" + payload?.from_date
          : ""
      }${payload?.to_date !== undefined ? "&to_date=" + payload?.to_date : ""}`
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        {
          if (res?.message === "Unauthenticated.") {
            localStorage.clear();
            window.location = "/";
            window.location.reload();
          } else {
            payload?.setLoading(false);

            res.data &&
              res.data.data.url.map((v, e) => {
                window.open(
                  `${url.base_url}/download?url=${v.url}&filename=${v.filename}`
                );
              });
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }
        if (err?.response?.status === 401) {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.statusText, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchAllChallan: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";

    getData(
      `${url.base_url}${url.challangetdata}${
        "?company_id=" + localStorage.getItem("companyName")
      }${searchQuery}${
        payload?.customer_id !== undefined
          ? "&customer_id=" + payload?.customer_id
          : ""
      }${
        payload?.parent_id !== undefined
          ? "&parent_id=" + payload?.parent_id
          : ""
      }${searchQuery}${
        payload?.pagination ? "&page=" + payload?.pagination : ""
      }${
        payload?.from_date !== undefined
          ? "&from_date=" + payload?.from_date
          : ""
      }${payload?.to_date !== undefined ? "&to_date=" + payload?.to_date : ""}`
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
      });
  },

  fetchChartOfAccount: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";
    getData(
      url.base_url +
        url.chartAccountGet +
        "?company_id=" +
        localStorage.getItem("companyName") +
        searchQuery
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        payload?.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }
      });
  },

  fetchTemplateGett: (payload, cb, timeout) => {
    const searchQuery = payload?.params ?? "";
    payload.setLoading(true);
    getData(
      url.base_url +
        url.tempateAll +
        "?company_id=" +
        localStorage.getItem("companyName") +
        searchQuery
    )
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        payload.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          // Redirect to the login page
          localStorage.clear();
          window.location.reload();
        }
      });
  },

  fetchDeleteeyTemplatess: (payload, cb, timeout) => {
    payload.setLoading(false);
    NewPostData(url.base_url + url.tempaltedeltee, { id: payload.id })
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  },

  EditREcuringMail: (payload, cb, timeout) => {
    const dasta = payload;

    NewPostData(url.base_url + url.updaterecuringemail, dasta)
      .then((res) => {
        payload.setLoading(false);
        toast.success("Recuring Email Edit Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
        setTimeout(() => {
          window.location.href = "/recuring/all-recuring-email";
        }, 500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },

  fetchEditttTemplatee: (payload, cb, timeout) => {
    const dasta = payload;

    NewPostData(url.base_url + url.tempalteeditt, dasta)
      .then((res) => {
        payload.setLoading(false);
        toast.success("Template Edit Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);

        // window.templateUpdateTable();
        setTimeout(() => {
          window.location.href = "/domain-parameter/all-templates";
        }, 1500);
      })
      .catch((err) => {
        payload.setLoading(false);

        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();

          toast.error(err?.response?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: "toastStyle",
          });
        }
      });
  },
};

function PostData(URL, data) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(URL, data, config);
}

export function NewPostData(URL, data) {
  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(URL, data, config);
}

function download(url, filename) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = "_blank";
  link.click();
}

export function PostRegisterData(URL, data) {
  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(URL, data, config);
}

function removeData(URL, data) {
  return axios.delete(URL);
}

function getData(URL) {
  let token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get(URL, config);
}
