import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Grid, InputLabel, FormControl, Stack, Button } from "@mui/material";
import { Spin, Table, Space, Input, Modal, Tooltip } from "antd";
import { dispatch } from "store/index";
import { fetchViewQuotation } from "store/action/index";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FilePdfOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { color } from "../../../node_modules/@mui/system/index";
import { useNavigate } from "react-router-dom";
// ==============================|| Create / Edit Company - SICPA ||============================== //

const ViewSingleQuotation = ({ viewQuotionData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pdfUrl, setpdfUrl] = useState("");
  const [pdfUrl2, setpdfUrl2] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewMainQuotation, setViewMainQuotation] = useState({});
  const location = useLocation();
  const currentRoute = location.pathname;
  const [fields, setFields] = useState([
    {
      description: "",
      qty: 0,
      unit_type: "asdsa",
      unit_price: 0,
      total_price: 0,
    },
  ]);
  const [taxData, setTaxData] = useState([]);
  const quotation_type = {
    1: "Recieving",
    2: "ADHOC",
    3: "Quotation Vice",
    no: "--empty--",
  };
  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      width: 100,
      key: "sno",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description*",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      render: (text, record, index) => (
        <Stack spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <span className="border p-1 rounded-2">
              {record?.description ?? ""}
            </span>
          </FormControl>
        </Stack>
      ),
    },

    {
      title: "Quantity*",
      dataIndex: "qty",
      key: "qty",
      render: (text, record, index) => (
        <Stack spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <span className="border p-1 rounded-2">{record?.qty ?? ""}</span>
          </FormControl>
        </Stack>
      ),
    },
    // {
    //     title: "Unit*",
    //     dataIndex: "unit_type",
    //     key: "unit",
    //     render: (text, record, index) => (
    //         <Stack spacing={1}>
    //             <FormControl variant="outlined" fullWidth>
    //                 <span className="border p-1 rounded-2">
    //                     {record?.unit_type ?? ""}
    //                 </span>
    //             </FormControl>
    //         </Stack>
    //     ),
    // },

    {
      title: "Unit Price*",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text, record, index) => (
        <Stack spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <span className="border p-1 rounded-2">
              {record?.unit_price ?? ""}
            </span>
          </FormControl>
        </Stack>
      ),
    },

    {
      title: "Gross Total*",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => (
        <Stack spacing={1}>
          <FormControl variant="outlined" fullWidth>
            <span className="border p-1 rounded-2">
              {record?.total_price ?? ""}
            </span>
          </FormControl>
        </Stack>
      ),
    },
  ];

  const convertTaxValue = (val) => {
    let ammount;
    if (val.tax_type === "1") {
      ammount =
        (Number(viewMainQuotation?.sub_total) / 100) * Number(val?.tax_value);
    } else {
      ammount = Number(val?.tax_value);
    }
    return ammount.toFixed(2);
  };

  useEffect(() => {
    console.log("CurrentRoute:", currentRoute);
  }, [currentRoute]);

  useEffect(() => {
    console.log("CurrentRdsdsdsdsdoute:", viewMainQuotation);
  }, [viewMainQuotation]);

  useEffect(() => {
    if (viewQuotionData && viewQuotionData?.main_quotation) {
      // setpdfUrl(
      //   viewQuotionData.url + "/" + viewQuotionData?.main_quotation?.file_path
      // );
      // setpdfUrl2(
      //   viewQuotionData.url + "/" + viewQuotionData?.main_quotation?.file_path2
      // );

      setpdfUrl(viewQuotionData?.main_quotation?.file_path);
      setpdfUrl2(viewQuotionData?.main_quotation?.file_path2);
      setViewMainQuotation(viewQuotionData?.main_quotation);
      setFields(viewQuotionData?.main_quotation?.qt_items);
      setTaxData(viewQuotionData?.main_quotation?.taxes);
    }
    console.log("fddfdfdf", viewQuotionData?.main_quotation?.is_lock);
    console.log("fddfdfddfdfdfdfdfdfdf", viewQuotionData);
    console.log("file_path with watermark:", viewQuotionData?.main_quotation?.file_path);
    console.log("file_path without watermark:", viewQuotionData?.main_quotation?.file_path2);
  }, [viewQuotionData]);

  useEffect(() => {
    const payload = { id: id };
    dispatch(
      fetchViewQuotation({
        payload,
        setLoading: setLoading,
      })
    );
  }, []);

  const handleViewPDF = (type = null) => {
    window.open(type == "without" ? pdfUrl2 : pdfUrl);
  };
//   const handleViewPDF = (type = null) => {
//     const url = type === "without" ? pdfUrl2 : pdfUrl;
//     console.log("Opening URL:", url);
//     window.open(url);
// };


  // const handleApproved = (id, params) => {
  //   navigate(`/invoice/create-invoice/${id}`, { state: params });
  //   console.log("padsdsdsdssdrams", params);
  // };

  const handleApproved = (id, params) => {
    navigate(`/invoice/create-invoice/${id}`, { state: { ...params, source: "quotation" } });
    console.log("params", params);
  };
  

  return (
    <MainCard>
      <Spin spinning={loading}>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <div className="row">
              <div className=" d-flex ">
                <div className="my-2">
                  <Tooltip title="View PDF With WaterMark">
                    <Button
                      onClick={() => handleViewPDF()}
                      disableElevation
                      variant="contained"
                      style={{ height: "50px", color: "#fff" }}
                    >
                      PDF with watermark
                      <FilePdfOutlined
                        className="px-3"
                        style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        size="medium"
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip title="View PDF Without WaterMark">
                    <Button
                      className="mx-2"
                      onClick={() => handleViewPDF("without")}
                      disableElevation
                      variant="contained"
                      style={{ height: "50px", color: "#fff" }}
                    >
                      PDF without watermark
                      <FilePdfOutlined
                        className="px-3"
                        style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        size="medium"
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div className="my-2 mx-2">
                  {viewMainQuotation?.status === 1 && (
                    <Tooltip title="Approved">
                      {/* <Link to={`/invoice/create-invoice/${id}`}> */}
                        <CheckCircleOutlined
                          disableElevation  
                          className="px-3"
                          size="medium"
                          type="submit"
                          variant="contained"
                          style={{
                            height: "50px",
                            fontSize: "45px",
                            color: "#ED1D24",
                          }}
                          // onClick={() => handleApproved()}
                          onClick={() => handleApproved(id,viewMainQuotation)}

                        />
                      {/* </Link> */}
                    </Tooltip>
                  )}
                  {viewMainQuotation?.status === 2 && (
                    <span className="btn btn-primary">Approved</span>
                  )}
                  {viewMainQuotation?.status === 3 && (
                    <span className="btn btn-info">Draft</span>
                  )}
                  {viewMainQuotation?.status === 5 && (
                    <span className="btn btn-danger">Expired</span>
                  )}
                </div>
              </div>
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Qoutation Number
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.quote_num ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Company
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.company_name ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Customer
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.customer_name ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Quotation Type
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {quotation_type[
                        viewMainQuotation?.quotation_type ?? "no"
                      ] ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Date
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.date ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Quotation Serial Number
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.number ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Payment term
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.payment_term ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Currency
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.currency ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Expiry Date
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.valid_till ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    PDF Password
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <span className="border p-3 rounded-2">
                      {viewMainQuotation?.pdf_password ?? ""}
                    </span>
                  </FormControl>
                </Stack>
              </div>
            </div> */}

            <div className="row">
              <Table
                className="rounded"
                pagination={false}
                columns={columns}
                dataSource={fields}
                scroll={{
                  x: 1300,
                }}
              />
            </div>
            <div className="row d-flex justify-content-end mt-3">
              <div className="col-md-6 my-2">
                <ThemeInput
                  name="Sub Total*"
                  disabled={true}
                  value={viewMainQuotation?.sub_total ?? ""}
                  placeholder="Total"
                  // onChange={(e) => setpayment_term( e.target.value )}
                />
              </div>
            </div>
            <div className="">
              {taxData && taxData.length ? (
                taxData.map((val, index) => (
                  <div key={index} className="row justify-content-end">
                    <div className="col-md-3 my-2 pl-2">
                      <Stack spacing={1}>
                        <InputLabel
                          htmlFor="AssignRole"
                          style={{ fontSize: "13px" }}
                        >
                          Tax 1
                        </InputLabel>
                        <FormControl variant="outlined" fullWidth>
                          <span className="border p-3 rounded-2">
                            {val.tax_name}
                          </span>
                        </FormControl>
                      </Stack>
                    </div>
                    <div className="col-md-3 my-2">
                      <Stack spacing={1}>
                        <InputLabel
                          htmlFor="AssignRole"
                          style={{ fontSize: "13px" }}
                        >
                          Tax Amount
                        </InputLabel>
                        <FormControl variant="outlined" fullWidth>
                          <span className="border p-3 rounded-2">
                            {convertTaxValue(val)}
                          </span>
                        </FormControl>
                      </Stack>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>

            <div className="row justify-content-end">
              <div className="col-md-6 my-2">
                <ThemeInput
                  name="Total*"
                  disabled={true}
                  value={viewMainQuotation?.grand_total ?? ""}
                  placeholder="Total"
                  // onChange={(e) => setpayment_term( e.target.value )}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Spin>
    </MainCard>
  );
};

const mapStateToProps = (state) => {
  return {
    viewQuotionData: state?.quotationView?.viewQuotation?.data,
  };
};
export default connect(mapStateToProps)(ViewSingleQuotation);
